export default function useCampsiteDetailDescription({
  campsite: pCampsite = null,
}: {
  campsite: MaybeRefOrGetter<any | null>;
}) {
  const campsite = toRef(pCampsite);

  /********************
   * COMPOSITIONS      *
   ********************/
  const { $gettext, interpolate, $pgettext } = useGettext();
  const { isIntegrationProvider } = useCampsiteAdsAs({ campsite: () => campsite.value });
  const { openingDatesAsDates } = useCampsiteDetailOperatingHours({ campsite: () => campsite.value });

  /********************
   * FUNCTIONS         *
   ********************/
  function getValueOrNull(val) {
    if (val === false) {
      return false;
    }
    return val || null;
  }

  function isOnSite(value) {
    if (value === undefined || value === null) {
      return false;
    }
    return value === 0;
  }

  function getDescription() {
    const transLocation
      = campsite.value?.meta?.city_obj?.name
      && campsite.value?.meta?.city_obj?.name !== campsite.value?.meta.federal_state.name
        ? $gettext('%{campsite_name} is located in %{campsite_city}, %{campsite_federalstate}, %{campsite_country}')
        : $gettext('%{campsite_name} is located in %{campsite_federalstate}, %{campsite_country}');

    // opening
    const hasOpeningPeriod = !!openingDatesAsDates.value?.length;
    const operatingPeriod = openingDatesAsDates.value?.reduce((accumulator, currentValue, currentIndex) => {
      const isLastConnector = currentIndex + 1 === openingDatesAsDates.value?.length - 1;
      return (
        accumulator
        + `${currentValue.start.format('DD.MM')} - ${currentValue.end.format('DD.MM')}${
          isLastConnector ? ' & ' : currentIndex + 1 === openingDatesAsDates.value?.length ? '' : ','
        }`
      );
    }, '');
    const transOpening = $gettext('%{campsite_name} is in operation from %{operating_period}');
    const interpolatedOpening = interpolate(
      transOpening,
      { campsite_name: campsite.value?.meta.name, operating_period: operatingPeriod },
      true,
    );
    const openingText = hasOpeningPeriod ? interpolatedOpening : transLocation;

    // distance info
    const transAtSea = $gettext('It is directly by the sea');
    const transAtLake = $gettext('It is directly on a lake');
    const transAtRiver = $gettext('It is directly on a river');
    const isAtSea = isOnSite(campsite.value?.distances?.distance_sea);
    const isAtLake = isOnSite(campsite.value?.distances?.distance_lake);
    const isAtRiver = isOnSite(campsite.value?.distances?.distance_river);
    const isAt = isAtSea || isAtLake || isAtRiver || false;
    const isAtText = (isAtSea && transAtSea) || (isAtLake && transAtLake) || (isAtRiver && transAtRiver);
    const distanceText = isAt ? [`${isAtText} 🌊`] : [];

    // dog info
    const dogsAllowedMainSeason = getValueOrNull(campsite.value?.general?.dogs_allowed_season);
    const dogsAllowedOffSeason = getValueOrNull(campsite.value?.general?.dogs_allowed_off_season);
    let dogText = null;
    let dogsAllowed = null;
    if (dogsAllowedMainSeason !== null || dogsAllowedOffSeason !== null) {
      if (dogsAllowedMainSeason === true && dogsAllowedOffSeason === true) {
        dogsAllowed = true;
        dogText = $gettext('Dogs are allowed');
      } else if (dogsAllowedMainSeason === false && dogsAllowedOffSeason === false) {
        dogsAllowed = false;
        dogText = $gettext('Dogs are prohibited');
      } else if (dogsAllowedMainSeason === true) {
        dogsAllowed = true;
        dogText = $gettext('Dogs are allowed in high season');
      } else if (dogsAllowedOffSeason === true) {
        dogsAllowed = true;
        dogText = $gettext('Dogs are allowed in the low season');
      }
    }
    const dogsText = dogsAllowed === null ? [] : !dogsAllowed ? [`${dogText} ❌`] : [`${dogText} 🐕`];

    // bookable
    const isOnlineBookable = isIntegrationProvider.value;
    const bookableText = isOnlineBookable ? $gettext('Book online now') : $gettext('Discover now');

    let description;
    if (hasOpeningPeriod || isAt || dogText || isOnlineBookable) {
      description = [openingText, ...distanceText, ...dogsText, bookableText].join('. ');
    } else {
      description = [openingText, $pgettext('cpdp description', 'Discover this campsite now')].join('. ');
    }

    return description + ' ►';
  }

  return {
    getDescription,
  };
}
